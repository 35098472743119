@import "./utils";
@import "~jason-css/src/responsive/index";
@import "../fonts/iconfont.css";

@font-face {
  font-family: 'itc';
  src: url('../fonts/ITCAvantGardePro-Book_0.woff') format('woff'),
    url('../fonts/ITCAvantGardePro-Book_0.ttf') format('truetype'),
    url('../fonts/ITCAvantGardePro-Book_0.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'itc-b';
  src: url('../fonts/ITCAvantGardePro-Bold.woff') format('woff'),
    url('../fonts/ITCAvantGardePro-Bold.ttf') format('truetype'),
    url('../fonts/ITCAvantGardePro-Bold.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
// @font-face {
//   font-family: 'futura-pt';
//   src: url('../fonts/FuturaPTLight.otf') format('otf');
//   font-weight: normal;
//   font-style: normal;
// }
@font-face {
  font-family: 'futura-pt';
  src: url(../fonts/FuturaPTLight.otf);
  font-weight: normal;
    font-style: normal;
}
@font-face {
  font-family: 'futura-pt-b';
  src: url(../fonts/FuturaPTBold.otf);
  font-weight: normal;
  font-style: normal;
}
*{
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  outline:none;
  //文字中空描边
  // color: transparent;
  // -webkit-text-stroke: 1px #fff;
  // -webkit-font-smoothing: antialiased;
}

body {
  font-family: 'itc', '微软雅黑';
  overflow-x: hidden;
  line-height: 1.2;
  position: relative;
  color: $color-text;
  background-color: #fff;
  @include res(font-size, $text-size-base, $text-size-ratio);
}

//清除浮动
.clear {
  zoom: 1;

  &::after {
    content: '';
    display: block;
    height: 0;
    visibility: hidden;
    clear: both;
  }
}

.comp-root{
  overflow-x: hidden;
}

.pd120{
  @include res(padding-left,120px, 10 / 120);
  @include res(padding-right,120px, 10 / 120);
}
.pd140{
  @include res(padding-left,140px, 0 / 140);
  @include res(padding-right,140px, 0 / 140);
}
.pd260{
  @include res(padding-left,260px, 10 / 260);
  @include res(padding-right,260px, 10 / 260);
}
.bg-gray{
  background-color: #f2f2f2;
  .page{
    a{background-color: #fff;}
  }
}

//分页
.page{
  display: flex;
  align-items: center;
  justify-content: center;
  @include res(margin-top,100px, 20 / 100);
  @include res(margin-bottom,50px, 30 / 50);
  a{
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background-color: $color-bg;
    transition: all .3s;
    @include res(margin,3px, 2 / 3);
    @include res(width,46px, 36 / 46);
    @include res(height,46px, 36 / 46);
    &:hover{
      color: $blue;
    }
    &.active{
      background-color: $blue;
      color: #fff;
    }
  }
}

// 圆形旋转
.common-circle{
  position: absolute;
  z-index: 1;
  @include res(width,147px,80 / 147);
  @include res(height,147px,80 / 147);
  &.reverse{
    .img{
      img,.iconfont{
        animation: rotate-reverse 16s linear infinite;
      }
    }
  }
  .img{
    img{
      display: block;
      width: 100%;
      height: auto;
      animation: rotate 16s linear infinite;
    }
    .iconfont{
      display: flex;
      align-items: center;
      justify-content: center;
      animation: rotate 16s linear infinite;
      color: rgba(0,0,0,.3);
      @include res(width,147px,80 / 147);
      @include res(height,147px,80 / 147);
      @include res(font-size,160px,80 / 160);
    }
  }
  .icon{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%,-50%,0);
    .iconfont{
      @include res(font-size,26px, 16 / 26);
    }
  }
}

// 旋转
@keyframes rotate{
  0%{
    transform: rotate(0);
  }
  50%{
    transform: rotate(180deg);
  }
  100%{
    transform: rotate(360deg);
  }
}
@keyframes rotate-reverse{
  0%{
    transform: rotate(360deg);
  }
  50%{
    transform: rotate(180deg);
  }
  100%{
    transform: rotate(0deg);
  }
}


//marketing公共导航和标题-pc端
.marketing-common-nav{
  font-family: 'itc-b';
  @include res(display,block,(xs:none));
  @include res(margin-bottom,20px, 10 / 20);
  .nav{
    overflow-x: auto;
    padding-bottom: 10px;
    @include res(margin-bottom,70px, 20 / 70);
    @include res(white-space,normal,(md:nowrap));
    &::-webkit-scrollbar {
      height: 3px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 5px rgba(97, 184, 179, 0.1);
      background: rgba(189,189,189,.6);
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    a{
      display: inline-block;
      border-radius: 50px;
      border: 1px solid #c6cbdb;
      cursor: pointer;
      color: #000;
      transition: all .3s;
      @include res(margin-right,3px, 1 / 3);
      @include res(margin-bottom,5px, 0 / 5);
      @include res(padding-top,15px, 10 / 15);
      @include res(padding-bottom,15px, 10 / 15);
      @include res(padding-left,20px, 10 / 20);
      @include res(padding-right,20px, 10 / 20);
      @include res(font-size,26px, 16 / 26);
      &:hover{
        color: $blue;
      }
      &.active{
        border: 1px solid $blue;
        color: #fff;
        background-color: $blue;
      }
    }
  }
  .title{
    color: $blue;
    line-height: 1;
    @include res(font-size,80px, 30 / 80);
  }
}

//marketing公共导航和标题-手机端
.marketing-common-nav-mob{
  font-family: 'itc-b';
  @include res(display,none,(xs:block));
  @include res(margin-bottom,20px, 10 / 20);
  .nav-cont{
    position: relative;
    z-index: 11;
    .title{
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 30px;
      border: 1px solid #c6cbdb;
      color: $blue;
      transition: all .2s;
      background-color: #fff;
      @include res(font-size,26px, 18 / 26);
      @include res(padding-left,20px, 20 / 20);
      @include res(padding-right,20px, 20 / 20);
      @include res(padding-top,15px, 15 / 15);
      @include res(padding-bottom,15px, 15 / 15);
      .iconfont{
        transition: all .2s;
      }
      &.active{
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        border-bottom: none;
        .iconfont{
          transform: rotate(90deg);
        }
      }
    }
    .nav{
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      z-index: 2;
      background-color: #fff;
      border-bottom-left-radius: 30px;
      border-bottom-right-radius: 30px;
      font-size: 16px;
      overflow: hidden;
      border: 1px solid #c6cbdb;
      border-top: none;
      a{
        display: block;
        padding: 15px 20px;
        &.active{
          background-color: $blue;
          color: #fff;
        }
      }
    }
  }
}