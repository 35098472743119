@import "../../assets/styles/utils";

.common-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 50;
    display: none;
}

header {
    .header-logo {
        position: absolute;
        z-index: 100;
        @include res(top, 30px, 10 / 30);
        @include res(left, 45px, 10 / 45);

        img {
            display: block;
            width: auto;
            @include res(height, 72px, 40 / 72);
        }
    }

    .header_contactus_btn{
        color: #fff;
        font-weight: bold; 
        font-family: 'itc-b';
        position: fixed;
        z-index: 100;
        transition: all .3s;
        // display: flex;
        // align-items: center;
        @include res(top, 34px, 10 / 34);
        @include res(right, 60px, 10 / 60);
        @include res(width, 340px, 230 / 340);
        @include res(height, 64px, 43 / 64);
        @include res(line-height, 64px, 43 / 64);
        @include res(font-size, 18px, 14 / 18);
        span{
            display: inline-block;
            position: relative;
            &::after{
                content: '';
                display: block;
                width: 0;
                height: 2px;
                background-color: #fff;
                transition: all .3s;
                position: absolute;
                @include res(bottom, 15px, 10 / 15);
            }
            &:hover{
                &::after{
                    width: 100%;
                }
            }
        }
    }

    .menu_shadow {
        position: absolute;
        width: 100%;
        height: 100%;
        display: none;
        z-index: 100;

        &.active {
            display: block;
        }
    }

    .open-menu {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        border-radius: 50px;
        position: fixed;
        z-index: 102;
        cursor: pointer;
        // box-shadow: 0 0 5px rgba(0,0,0,.3);
        transition: all .3s;
        @include res(top, 34px, 10 / 34);
        @include res(right, 60px, 10 / 60);
        @include res(width, 190px, 105 / 190);
        @include res(height, 64px, 43 / 64);

        // @include res(padding-top,20px, 10 / 20);
        // @include res(padding-bottom,20px, 10 / 20);
        // @include res(padding-left,45px, 15 / 45);
        // @include res(padding-right,35px, 10 / 35);
        &.active {
            background-color: transparent;

            .t {
                span {
                    &:first-child {
                        opacity: 0;
                    }

                    &:last-child {
                        opacity: 1;
                    }
                }
            }
        }

        .hamburger {
            @include res(width, 33px, 30 / 33);

            &.active {
                >div {
                    @include res(width, 33px, 30 / 33);

                    &::after {
                        background-color: $red;
                        @include res(width, 33px, 30 / 33);
                    }
                }
            }

            >div {
                background-color: $red;
                @include res(width, 22px, 20 / 22);
                @include res(height, 5px, 3 / 5);

                &::before {
                    background-color: $blue;
                    @include res(width, 33px, 30 / 33);
                    @include res(height, 5px, 3 / 5);
                    @include res(top, -9px, -7 / -9);
                }

                &::after {
                    background-color: $yellow;
                    @include res(width, 22px, 20 / 22);
                    @include res(height, 5px, 3 / 5);
                    @include res(bottom, -9px, -7 / -9);
                }
            }
        }

        .t {
            text-transform: uppercase;
            color: $blue;
            font-family: 'itc-b';
            position: relative;
            @include res(margin-top, 2px, 1 / 2);
            @include res(margin-left, 25px, 10 / 25);

            span {
                transition: all .25s;

                &:last-child {
                    opacity: 0;
                    position: absolute;
                    top: 0;
                    right: 0;
                }
            }
        }
    }

    .fixed-menu {
        position: fixed;
        z-index: 101;
        background-color: #fff;
        box-sizing: border-box;
        overflow: hidden;
        transition: all .45s $anime-bezier;
        @include res(border-radius, 50px, 30 / 50);
        @include res(width, 190px, 105 / 190);
        @include res(height, 64px, 43 / 64);
        @include res(top, 34px, 10 / 34);
        @include res(right, 60px, 10 / 60);

        &.active {
            @include res(width, 750px, (sm:80%, xs:calc(100% - 20px)));
            @include res(height, 93vh);
            @include res(padding-top, 120px, 60 / 120);
            @include res(padding-bottom, 40px, 20 / 40);
            @include res(padding-left, 80px, 15 / 80);
            @include res(padding-right, 20px, 5 / 20);
        }

        .cont {
            height: 100%;
            overflow-y: auto;
            display: none;
            @include res(padding-right, 20px, 10 / 20);

            &::-webkit-scrollbar {
                width: 4px;
            }

            &::-webkit-scrollbar-track {
                border-radius: 10px;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background: rgba(0, 0, 0, 0.1);
            }

            ul {
                transition: all .3s;
                color: $blue;

                &:hover {
                    color: rgba(27, 57, 147, .6);
                }

                li {
                    font-family: 'itc-b';
                    margin-bottom: 5px;
                    transition: all .3s;

                    &:hover {
                        color: rgba(27, 57, 147, 1);
                    }

                    &.active {
                        .nav-title {
                            .iconfont {
                                transform: rotate(90deg);
                            }
                        }
                    }

                    .nav-title {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        cursor: pointer;

                        span,
                        a {
                            text-transform: uppercase;
                            @include res(font-size, 66px, 30 / 66);
                        }

                        .iconfont {
                            color: $red;
                            transition: all .3s;
                            @include res(margin-left, 20px, 10 / 20);
                            @include res(font-size, 20px, 18 / 20);
                        }
                    }

                    .sub-nav {
                        display: none;

                        .pro-link {
                            display: flex;
                            align-items: flex-start;
                            justify-content: flex-start;
                            flex-wrap: wrap;
                            @include res(margin-top, 20px, 10 / 20);
                            @include res(margin-bottom, -20px, -10 / -20);

                            a {
                                display: flex;
                                align-items: center;
                                @include res(font-size, 20px, 16 / 20);
                                @include res(margin-right, 40px, 20 / 40);
                                @include res(margin-bottom, 15px, 10 / 15);

                                .icon-xiangyou {
                                    transition: all .3s;
                                    transform: rotate(90deg);
                                    @include res(font-size, 12px);
                                    @include res(margin-left, 10px);
                                }

                                .icon-caozuo-wailian {
                                    @include res(font-size, 20px);
                                    @include res(margin-left, 10px);
                                }
                            }
                        }

                        .link-list {
                            text-transform: uppercase;
                            @include res(margin-top, 20px, 10 / 20);
                            @include res(margin-bottom, 10px, 3 / 10);

                            a {
                                display: inline-block;
                                position: relative;
                                @include res(margin-bottom, 15px, 10 / 15);
                                @include res(margin-right, 35px, 15 / 35);
                                @include res(font-size, 22px, 18 / 22);

                                &::after {
                                    content: '';
                                    display: block;
                                    width: 0;
                                    height: 2px;
                                    background-color: $blue;
                                    transition: all .3s;
                                }

                                &:hover {
                                    &::after {
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}