.ny-banner {
  position: relative;
  overflow: hidden;
  border-bottom-right-radius: 50px;
  padding-top: 410px;
  padding-bottom: 160px;
  padding-left: 120px;
  padding-right: 120px;
  margin-bottom: 80px;
}

@media (max-width: 1600px) {
  .ny-banner {
    border-bottom-right-radius: 46px;
  }
}

@media (max-width: 1366px) {
  .ny-banner {
    border-bottom-right-radius: 42px;
  }
}

@media (max-width: 1024px) {
  .ny-banner {
    border-bottom-right-radius: 38px;
  }
}

@media (max-width: 991px) {
  .ny-banner {
    border-bottom-right-radius: 34px;
  }
}

@media (max-width: 767px) {
  .ny-banner {
    border-bottom-right-radius: 30px;
  }
}

@media (max-width: 1600px) {
  .ny-banner {
    padding-top: 368px;
  }
}

@media (max-width: 1366px) {
  .ny-banner {
    padding-top: 326px;
  }
}

@media (max-width: 1024px) {
  .ny-banner {
    padding-top: 284px;
  }
}

@media (max-width: 991px) {
  .ny-banner {
    padding-top: 242px;
  }
}

@media (max-width: 767px) {
  .ny-banner {
    padding-top: 200px;
  }
}

@media (max-width: 1600px) {
  .ny-banner {
    padding-bottom: 132px;
  }
}

@media (max-width: 1366px) {
  .ny-banner {
    padding-bottom: 104px;
  }
}

@media (max-width: 1024px) {
  .ny-banner {
    padding-bottom: 76px;
  }
}

@media (max-width: 991px) {
  .ny-banner {
    padding-bottom: 48px;
  }
}

@media (max-width: 767px) {
  .ny-banner {
    padding-bottom: 20px;
  }
}

@media (max-width: 1600px) {
  .ny-banner {
    padding-left: 99px;
  }
}

@media (max-width: 1366px) {
  .ny-banner {
    padding-left: 78px;
  }
}

@media (max-width: 1024px) {
  .ny-banner {
    padding-left: 57px;
  }
}

@media (max-width: 991px) {
  .ny-banner {
    padding-left: 36px;
  }
}

@media (max-width: 767px) {
  .ny-banner {
    padding-left: 15px;
  }
}

@media (max-width: 1600px) {
  .ny-banner {
    padding-right: 99px;
  }
}

@media (max-width: 1366px) {
  .ny-banner {
    padding-right: 78px;
  }
}

@media (max-width: 1024px) {
  .ny-banner {
    padding-right: 57px;
  }
}

@media (max-width: 991px) {
  .ny-banner {
    padding-right: 36px;
  }
}

@media (max-width: 767px) {
  .ny-banner {
    padding-right: 15px;
  }
}

@media (max-width: 1600px) {
  .ny-banner {
    margin-bottom: 68px;
  }
}

@media (max-width: 1366px) {
  .ny-banner {
    margin-bottom: 56px;
  }
}

@media (max-width: 1024px) {
  .ny-banner {
    margin-bottom: 44px;
  }
}

@media (max-width: 991px) {
  .ny-banner {
    margin-bottom: 32px;
  }
}

@media (max-width: 767px) {
  .ny-banner {
    margin-bottom: 20px;
  }
}

.ny-banner::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.2);
}

.ny-banner .bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
}

.ny-banner .title {
  position: relative;
  z-index: 3;
  color: #fff;
  font-family: 'itc-b';
  text-transform: uppercase;
  line-height: 1;
  font-size: 120px;
}

@media (max-width: 1600px) {
  .ny-banner .title {
    font-size: 104px;
  }
}

@media (max-width: 1366px) {
  .ny-banner .title {
    font-size: 88px;
  }
}

@media (max-width: 1024px) {
  .ny-banner .title {
    font-size: 72px;
  }
}

@media (max-width: 991px) {
  .ny-banner .title {
    font-size: 56px;
  }
}

@media (max-width: 767px) {
  .ny-banner .title {
    font-size: 40px;
  }
}
