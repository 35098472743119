@import "../../assets/styles/utils";

footer{
    @include res(margin-top,120px, 10 / 120);
    @include res(padding-left,60px, 10 / 60);
    @include res(padding-right,60px, 10 / 60);
    .footer{
        color: #fff;
        background-color: $red;
        background-image: url(./images/footer_bg.png);
        background-repeat: no-repeat;
        background-position: bottom center;
        background-size: 100% auto;
        align-items: flex-start;
        justify-content: space-between;
        transition: all .45s;
        @include res(display,flex,(xs:block));
        @include res(border-radius, 50px, 20 / 50);
        @include res(padding-top,50px, 20 / 50);
        @include res(padding-bottom,150px, 40 / 150);
        @include res(padding-left,85px, 10 / 85);
        @include res(padding-right,85px, 10 / 85);
        .left{
            flex-shrink: 0;
            @include res(width,55%,(md:65%,xs:100%));
            @include res(margin-bottom,0,(xs:20px));
            .c1{
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                img{
                    &:nth-child(1){
                        height: auto;
                        @include res(width,170px, 120 / 170);
                    }
                    &:nth-child(2){
                        height: auto;
                        // @include res(display,null,(sm:none));
                        @include res(margin-left,60px, 20 / 60);
                        @include res(margin-top,10px, 10 / 10);
                        @include res(width,390px, 200 / 390);
                    }
                }
            }
            .c2{
                text-transform: uppercase;
                font-family: 'itc-b';
                display: flex;
                align-items: flex-start;
                flex-wrap: wrap;
                @include res(justify-content,flex-start,(xs:center));
                @include res(margin-top,50px, 15 / 50);
                @include res(font-size,30px, 16 / 30);
                a{
                    @include res(margin-right,45px, 20 / 45);
                    &::after{
                        content: '';
                        display: block;
                        width: 0;
                        height: 2px;
                        background-color: #fff;
                        transition: all .3s;
                    }
                    &:hover{
                        &::after{
                            width: 100%;
                        }
                    }
                }
            }
            .c3{
                display: flex;
                align-items: flex-start;
                flex-wrap: wrap;
                @include res(justify-content,flex-start,(xs:center));
                @include res(margin-top,50px, 20 / 50);
                a{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    border: 1px solid #fff;
                    transition: all .3s;
                    position: relative;
                    @include res(margin-right,20px, 10 / 20);
                    @include res(width,50px, 40 / 50);
                    @include res(height,50px, 40 / 50);
                    .iconfont{
                        transition: all .3s;
                        @include res(font-size,30px, 20 / 30);
                    }
                    &:hover{
                        background-color: #fff;
                        .ewm-img{
                            opacity: 1;
                            visibility: visible;
                        }
                        &:nth-child(1){
                            .iconfont{color: #1278F3;}
                        }
                        &:nth-child(2){
                            .iconfont{color: #2D8DC5;}
                        }
                        &:nth-child(3){
                            .iconfont{color: #0B66C3;}
                        }
                        &:nth-child(4){
                            .iconfont{color: #FE0000;}
                        }
                        &:nth-child(5){
                            .iconfont{color: #05B30F;}
                        }
                    }
                    .ewm-img{
                        position: absolute;
                        top: 115%;
                        left: 50%;
                        transform: translateX(-50%);
                        border-radius: 5px;
                        background-color: #fff;
                        opacity: 0;
                        visibility: hidden;
                        transition: all .3s;
                        @include res(padding,5px, 3 / 5);
                        img{
                            display: block;
                            height: auto;
                            @include res(width,150px, 100 / 150);
                        }
                        &::after{
                            content: '';
                            display: block;
                            border: 5px solid;
                            border-color: transparent transparent #fff transparent;
                            position: absolute;
                            bottom: 100%;
                            left: 50%;
                            transform: translateX(-50%);
                        }
                    }
                }
            }
            .c4{
                @include res(margin-top,40px, 15 / 40);
                &::before{
                    content: '';
                    display: block;
                    height: 1px;
                    background-color: #fff;
                    @include res(margin,null,(xs:0 auto));
                    @include res(width,105px, 80 / 105);
                    @include res(margin-bottom,20px, 15 / 20);
                }
                .cont{
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                    span{
                        @include res(margin-bottom,15px, 10 / 15);
                        @include res(margin-left,12px, 8 / 12);
                        @include res(margin-right,12px, 8 / 12);
                    }
                    a{
                        transition: all .3s;
                        &:hover{
                            opacity: .8;
                        }
                    }
                }
            }
        }
        .right{
            flex-shrink: 0;
            @include res(width,32%,(sm:34%,xs:100%));
            .title{
                font-family: 'itc-b';
                @include res(font-size,30px, 18 / 30);
                @include res(margin-top, 15px, 10 / 15);
                @include res(margin-bottom,10px, 10 / 10);
            }
            .form{
                form{
                    align-items: flex-start;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    @include res(display,flex,(xs:block));
                    .form-item{
                        position: relative;
                        overflow: hidden;
                        z-index: 1;
                        border-bottom-style: solid;
                        border-bottom-color: #fff;
                        @include res(border-bottom-width,1px);
                        @include res(width,100%);
                        &.active{
                            label{
                                @include res(font-size, 14px, 12 / 14);
                                @include res(top,-10px, -8 / -10);
                            }
                        }
                        label{
                            position: absolute;
                            left: 2px;
                            z-index: -1;
                            transition: all .3s;
                            color: #fff;
                            @include res(top,8px, 5 / 8);
                            @include res(line-height,50px, 40 / 50);
                            span{
                                color: #fff;
                            }
                        }
                        input{
                            width: 100%;
                            margin-top: 10px;
                            background: none;
                            border: none;
                            outline: none;
                            color: #fff;
                            @include res(height,50px, 40 / 50);
                        }
                        textarea{
                            width: 100%;
                            background: none;
                            border: none;
                            outline: none;
                            color: #fff;
                            @include res(margin-top,10px, 10 / 10);
                        }
                    }
                    .form-btn{
                        width: 100%;
                        @include res(margin-top,20px, 10 / 20);
                        button{
                            border: 1px solid #fff;
                            color: #000;
                            background-color:#fff;
                            cursor: pointer;
                            transition: all .3s;
                            box-sizing: border-box;
                            border-radius: 50px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            text-transform: uppercase;
                            @include res(margin,null,(xs:0 auto));
                            @include res(padding-left,45px, 30 / 45);
                            @include res(padding-right,45px, 30 / 45);
                            @include res(padding-top,20px, 10 / 20);
                            @include res(padding-bottom,20px, 10 / 20);
                            >div{
                                display: flex;
                                flex-direction: column;
                                overflow: hidden;
                                @include res(height,20px,16 / 20);
                                span{
                                    transition: all .3s;
                                }
                            }
                            &:hover{
                                >div{
                                    span{
                                        transform: translateY(-100%);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .copyright{
        align-items: center;
        justify-content: space-between;
        @include res(text-align,null,(sm:center));
        @include res(display,flex,(sm:block));
        @include res(padding-top,30px, 10 / 30);
        @include res(padding-bottom,50px, 10 / 50);
        @include res(padding-left,60px, 0 / 60);
        @include res(padding-right,60px, 0 / 60);
        @include res(font-size,16px, 12 / 16);
        a{
            transition: all .3s;
            &:hover{
                color: #000;
            }
        }
        .right{
            align-items: center;
            justify-content: flex-end;
            @include res(display,flex,(sm:block));
            @include res(margin-top,0,(sm:5px));
            span{
                @include res(margin-left,8px, 5 / 8);
                @include res(margin-right,8px, 5 / 8);
            }
            .site-map{
                @include res(display,null,(sm:none));
            }
        }
    }
}