@import "../../assets/styles/utils";

.common-more{
    border-radius: 30px;
    display: inline-block;
    border: 1px solid $blue;
    color: $blue;
    @include res(padding-left,35px, 20 / 35);
    @include res(padding-right,35px, 20 / 35);
    @include res(padding-top,15px, 10 / 15);
    @include res(padding-bottom,15px, 10 / 15);
    &:hover{
        .scroll-cont{
            div{
                transform: translateY(-100%);
            }
        }
    }
    &.blue{
        border: 1px solid $blue;
        background-color: $blue;
        color: #fff;
    }
    &.white{
        border: 1px solid #fff;
        background-color: #fff;
        color: $blue;
    }
    .scroll-cont{
        overflow: hidden;
        display: flex;
        flex-direction: column;
        @include res(height,21px, 16 / 21);
        div{
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all .25s;
            @include res(height,21px, 16 / 21);
            .iconfont{
                @include res(font-size,12px);
                @include res(margin-right,10px, 5 / 10);
            }
        }
    }
}