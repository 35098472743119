@import "../../assets/styles/utils";

.ny-banner{
    position: relative;
    overflow: hidden;
    @include res(border-bottom-right-radius,50px, 30 / 50);
    @include res(padding-top,410px, 200 / 410);
    @include res(padding-bottom,160px, 20 / 160);
    @include res(padding-left,120px, 15 / 120);
    @include res(padding-right,120px, 15 / 120);
    @include res(margin-bottom,80px, 20 / 80);
    &::after{
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background-color: rgba(0,0,0,.2);
    }
    .bg{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
    }
    .title{
        position: relative;
        z-index: 3;
        color: #fff;
        font-family: 'itc-b';
        text-transform: uppercase;
        line-height: 1;
        @include res(font-size,120px, 40 / 120);
    }
}