@font-face {
  font-family: "iconfont"; /* Project id 3398623 */
  src: url('iconfont.woff2?t=1682571777314') format('woff2'),
       url('iconfont.woff?t=1682571777314') format('woff'),
       url('iconfont.ttf?t=1682571777314') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-31dianhua:before {
  content: "\e602";
}

.icon-wxbdingwei:before {
  content: "\e624";
}

.icon-zuojiantou:before {
  content: "\e601";
}

.icon-instagram-fill:before {
  content: "\e88f";
}

.icon-yuyinguanbi:before {
  content: "\e903";
}

.icon-sanjiaoxing:before {
  content: "\e608";
}

.icon-QUALITY:before {
  content: "\e609";
}

.icon-caozuo-wailian:before {
  content: "\e711";
}

.icon-xiangzuo:before {
  content: "\ee49";
}

.icon-email:before {
  content: "\e69f";
}

.icon-link:before {
  content: "\e6f7";
}

.icon-xiangyou:before {
  content: "\e632";
}

.icon-youtube-play:before {
  content: "\e763";
}

.icon-file-pdf:before {
  content: "\e7b8";
}

.icon-twitter:before {
  content: "\e882";
}

.icon-Play:before {
  content: "\eb78";
}

.icon-weixin:before {
  content: "\e613";
}

.icon-dingweimian:before {
  content: "\e611";
}

.icon-facebook:before {
  content: "\e66a";
}

.icon-xiazai:before {
  content: "\e617";
}

.icon-daoru-01:before {
  content: "\e635";
}

.icon-lingying:before {
  content: "\ee48";
}

.icon-a-247:before {
  content: "\e600";
}

